import types from '../mutation-types';
import ReportsAPI from 'dashboard/api/reports';

// Cache duration in milliseconds (15 minutes)
const CACHE_DURATION = 15 * 60 * 1000;

// Keep track of in-flight requests to prevent duplicates
let pendingRequest = null;

const initialState = {
  metrics: {
    totalConversationsCount: 0,
    retentionRate: 0,
    handoffRate: 0,
    botCsatResponses: 0,
    botSatisfactionScore: 0,
  },
  uiFlags: {
    isFetchingMetrics: false,
  },
  lastFetched: null,
};

export const getters = {
  getMetrics: $state => $state.metrics,
  getUIFlags: $state => $state.uiFlags,
};

export const actions = {
  async getMetrics(
    { commit, state },
    { from, to, businessHours, classification_id } = {}
  ) {
    // Check if we have cached data that's still valid
    const now = new Date();
    if (
      state.lastFetched &&
      now - state.lastFetched < CACHE_DURATION &&
      state.metrics.totalConversationsCount > 0
    ) {
      return state.metrics;
    }

    // If there's already a request in progress, return that instead of making a new one
    if (pendingRequest) {
      return pendingRequest;
    }

    commit(types.SET_BOT_UI_FLAG, { isFetchingMetrics: true });
    
    // Create a new request and store it
    pendingRequest = new Promise(resolve => {
      const fetchData = async () => {
        try {
          const response = await ReportsAPI.getBotMetrics({
            from,
            to,
            businessHours,
            classification_id,
          });
          
          // Transform the API response to match the expected format in the component
          const metrics = {
            totalConversationsCount: response.data.conversation_count || 0,
            retentionRate: response.data.retention_rate || 0,
            handoffRate: response.data.handoff_rate || 0,
            // These fields might not be in the API response, but are used in the component
            botCsatResponses: response.data.bot_csat_responses || 0,
            botSatisfactionScore: response.data.bot_satisfaction_score || 0,
          };
          
          commit(types.SET_BOT_METRICS, metrics);
          commit('SET_LAST_FETCHED', now);
          resolve(metrics);
        } catch (error) {
          // Handle error silently but log it
          resolve(state.metrics);
        } finally {
          commit(types.SET_BOT_UI_FLAG, { isFetchingMetrics: false });
          // Clear the pending request
          pendingRequest = null;
        }
      };
      
      fetchData();
    });
    
    return pendingRequest;
  },
  
  // Add a method to clear the cache when needed
  clearCache({ commit }) {
    commit('SET_LAST_FETCHED', null);
  },
};

export const mutations = {
  [types.SET_BOT_METRICS]($state, metrics) {
    $state.metrics = metrics;
  },
  [types.SET_BOT_UI_FLAG]($state, flag) {
    $state.uiFlags = {
      ...$state.uiFlags,
      ...flag,
    };
  },
  SET_LAST_FETCHED($state, timestamp) {
    $state.lastFetched = timestamp;
  },
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations,
}; 