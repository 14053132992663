import types from '../mutation-types';
import ConversationApi from '../../api/inbox/conversation';

// Debounce helper function
const debounce = (fn, delay) => {
  let timer = null;
  return function(...args) {
    if (timer) clearTimeout(timer);
    return new Promise(resolve => {
      timer = setTimeout(() => {
        resolve(fn.apply(this, args));
      }, delay);
    });
  };
};

const state = {
  mineCount: 0,
  unAssignedCount: 0,
  allCount: 0,
  updatedOn: null,
};

export const getters = {
  getStats: $state => $state,
};

// Debounced API call function
const fetchMetaStats = async (params) => {
  try {
    const response = await ConversationApi.meta(params);
    const { data: { meta } } = response;
    return meta;
  } catch (error) {
    // Ignore error
    return null;
  }
};

// Create a debounced version with 500ms delay
const debouncedFetchMetaStats = debounce(fetchMetaStats, 500);

export const actions = {
  get: async ({ commit, state: $state }, params) => {
    const currentTime = new Date();
    const lastUpdatedTime = new Date($state.updatedOn || 0);
    const timeSinceLastUpdate = currentTime - lastUpdatedTime;
    
    // Skip if updated recently (30 seconds) unless it's a forced refresh
    if (timeSinceLastUpdate < 30000 && !params?.forceRefresh) {
      return;
    }

    const meta = await debouncedFetchMetaStats(params);
    if (meta) {
      commit(types.SET_CONV_TAB_META, meta);
    }
  },
  set({ commit }, meta) {
    commit(types.SET_CONV_TAB_META, meta);
  },
};

export const mutations = {
  [types.SET_CONV_TAB_META](
    $state,
    {
      mine_count: mineCount,
      unassigned_count: unAssignedCount,
      all_count: allCount,
    } = {}
  ) {
    $state.mineCount = mineCount;
    $state.allCount = allCount;
    $state.unAssignedCount = unAssignedCount;
    $state.updatedOn = new Date();
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
