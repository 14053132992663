/* eslint no-console: 0 */
import * as types from '../mutation-types';
import Report from '../../api/reports';
import ReportsAPI from '../../api/reports';
import { downloadCsvFile, generateFileName } from '../../helper/downloadHelper';
import AnalyticsHelper from '../../helper/AnalyticsHelper';
import { REPORTS_EVENTS } from '../../helper/AnalyticsHelper/events';
import {
  reconcileHeatmapData,
  clampDataBetweenTimeline,
} from 'shared/helpers/ReportsDataHelper';

// Helper functions for local storage caching
const CACHE_EXPIRY = 15 * 60 * 1000; // 15 minutes in milliseconds
const HEATMAP_CACHE_EXPIRY = 60 * 60 * 1000; // 1 hour in milliseconds

// Helper to get current account ID from URL
const getCurrentAccountIdFromURL = () => {
  const isInsideAccountScopedURLs = window.location.pathname.includes('/app/accounts');
  if (isInsideAccountScopedURLs) {
    return window.location.pathname.split('/')[3];
  }
  return '';
};

const getFromCache = key => {
  try {
    // Add account ID to cache key to isolate data between accounts
    const accountId = getCurrentAccountIdFromURL();
    const accountScopedKey = accountId ? `${accountId}_${key}` : key;
    
    const cachedData = localStorage.getItem(accountScopedKey);
    if (!cachedData) return null;
    
    const { data, timestamp } = JSON.parse(cachedData);
    const now = new Date().getTime();
    
    // Check if cache is expired
    if (now - timestamp > CACHE_EXPIRY) {
      localStorage.removeItem(accountScopedKey);
      return null;
    }
    
    return data;
  } catch (error) {
    console.error('Error retrieving from cache:', error);
    return null;
  }
};

const getFromHeatmapCache = key => {
  try {
    // Add account ID to cache key to isolate data between accounts
    const accountId = getCurrentAccountIdFromURL();
    const accountScopedKey = accountId ? `${accountId}_${key}` : key;
    
    const cachedData = localStorage.getItem(accountScopedKey);
    if (!cachedData) return null;
    
    const { data, timestamp } = JSON.parse(cachedData);
    const now = new Date().getTime();
    
    // Check if cache is expired
    if (now - timestamp > HEATMAP_CACHE_EXPIRY) {
      localStorage.removeItem(accountScopedKey);
      return null;
    }
    
    return data;
  } catch (error) {
    console.error('Error retrieving from heatmap cache:', error);
    return null;
  }
};

const saveToCache = (key, data) => {
  try {
    // Add account ID to cache key to isolate data between accounts
    const accountId = getCurrentAccountIdFromURL();
    const accountScopedKey = accountId ? `${accountId}_${key}` : key;
    
    const cacheData = {
      data,
      timestamp: new Date().getTime(),
    };
    localStorage.setItem(accountScopedKey, JSON.stringify(cacheData));
  } catch (error) {
    console.error('Error saving to cache:', error);
  }
};

const state = {
  fetchingStatus: false,
  accountReport: {
    isFetching: {
      conversations_count: false,
      incoming_messages_count: false,
      outgoing_messages_count: false,
      avg_first_response_time: false,
      avg_resolution_time: false,
      resolutions_count: false,
      bot_resolutions_count: false,
      bot_handoffs_count: false,
      reply_time: false,
      queue_time: false,
      operation_time: false,
    },
    data: {
      conversations_count: [],
      incoming_messages_count: [],
      outgoing_messages_count: [],
      avg_first_response_time: [],
      avg_resolution_time: [],
      resolutions_count: [],
      bot_resolutions_count: [],
      bot_handoffs_count: [],
      reply_time: [],
      queue_time: [],
      operation_time: [],
    },
  },
  accountSummary: {
    avg_first_response_time: 0,
    avg_resolution_time: 0,
    conversations_count: 0,
    incoming_messages_count: 0,
    outgoing_messages_count: 0,
    reply_time: 0,
    queue_time: 0,
    operation_time: 0,
    resolutions_count: 0,
    bot_resolutions_count: 0,
    bot_handoffs_count: 0,
    previous: {},
  },
  botSummary: {
    bot_resolutions_count: 0,
    bot_handoffs_count: 0,
    previous: {},
  },
  overview: {
    uiFlags: {
      isFetchingAccountConversationMetric: false,
      isFetchingAccountConversationsHeatmap: false,
      isFetchingAgentConversationMetric: false,
      isFetchingClassificationHandoffConversationMetric: false,
      isFetchingClassificationBadRatingConversationMetric: false,
      isFetchingClassificationConversationMetric: false
    },
    accountConversationMetric: {},
    accountConversationHeatmap: [],
    agentConversationMetric: [],
    classificationHandoffConversationMetric: [],
    classificationBadRatingConversationMetric: [],
    classificationConversationMetric: []
  },
};

const getters = {
  getAccountReports(_state) {
    return _state.accountReport;
  },
  getAccountSummary(_state) {
    return _state.accountSummary;
  },
  getBotSummary(_state) {
    return _state.botSummary;
  },
  getAccountConversationMetric(_state) {
    return _state.overview.accountConversationMetric;
  },
  getAccountConversationHeatmapData(_state) {
    return _state.overview.accountConversationHeatmap;
  },
  getAgentConversationMetric(_state) {
    return _state.overview.agentConversationMetric;
  },
  getClassificationHandoffConversationMetric(_state) {
    return _state.overview.classificationHandoffConversationMetric;
  },
  getClassificationBadRatingConversationMetric(_state) {
    return _state.overview.classificationBadRatingConversationMetric;
  },
  getClassificationConversationMetric(_state) {
    return _state.overview.classificationConversationMetric;
  },
  getOverviewUIFlags($state) {
    return $state.overview.uiFlags;
  },
};

export const actions = {
  fetchAccountReport({ commit }, reportObj) {
    const { metric } = reportObj;
    commit(types.default.TOGGLE_ACCOUNT_REPORT_LOADING, {
      metric,
      value: true,
    });
    if (reportObj?.id === undefined && reportObj?.type !== undefined) {
      commit(types.default.SET_ACCOUNT_REPORTS, {
        metric,
        data: [],
      });
      commit(types.default.TOGGLE_ACCOUNT_REPORT_LOADING, {
        metric,
        value: false,
      });
    } else {
      // Check cache first
      const cacheKey = `reports_account_${metric}_${reportObj.from}_${reportObj.to}_${reportObj.type}_${reportObj.id || 'null'}`;
      const cachedData = getFromCache(cacheKey);
      
      if (cachedData) {
        commit(types.default.SET_ACCOUNT_REPORTS, {
          metric,
          data: cachedData,
        });
        commit(types.default.TOGGLE_ACCOUNT_REPORT_LOADING, {
          metric,
          value: false,
        });
        return;
      }
      
      Report.getReports(reportObj).then(accountReport => {
        let { data } = accountReport;
        data = clampDataBetweenTimeline(data, reportObj.from, reportObj.to);
        
        // Save to cache
        saveToCache(cacheKey, data);
        
        commit(types.default.SET_ACCOUNT_REPORTS, {
          metric,
          data,
        });
        commit(types.default.TOGGLE_ACCOUNT_REPORT_LOADING, {
          metric,
          value: false,
        });
      });
    }
  },
  fetchAccountConversationHeatmap({ commit }, reportObj) {
    commit(types.default.TOGGLE_HEATMAP_LOADING, true);
    
    // Check cache first
    const cacheKey = `reports_heatmap_${reportObj.from}_${reportObj.to}`;
    const cachedData = getFromHeatmapCache(cacheKey);
    
    if (cachedData) {
      commit(types.default.SET_HEATMAP_DATA, cachedData);
      commit(types.default.TOGGLE_HEATMAP_LOADING, false);
      return;
    }
    
    Report.getReports({ ...reportObj, groupBy: 'hour' }).then(heatmapData => {
      let { data } = heatmapData;
      data = clampDataBetweenTimeline(data, reportObj.from, reportObj.to);

      data = reconcileHeatmapData(
        data,
        state.overview.accountConversationHeatmap
      );
      
      // Save to cache
      saveToCache(cacheKey, data);

      commit(types.default.SET_HEATMAP_DATA, data);
      commit(types.default.TOGGLE_HEATMAP_LOADING, false);
    });
  },
  fetchAccountSummary({ commit }, reportObj) {
    if (reportObj?.id === undefined && reportObj?.type !== undefined) {
      commit(types.default.SET_ACCOUNT_SUMMARY, {
        "conversations_count": null,
        "incoming_messages_count": null,
        "outgoing_messages_count": null,
        "avg_first_response_time": null,
        "avg_resolution_time": null,
        "resolutions_count": null,
        "reply_time": null,
        "queue_time": null,
        "operation_time": null,
        "previous": {
            "conversations_count": null,
            "incoming_messages_count": null,
            "outgoing_messages_count": null,
            "avg_first_response_time": null,
            "avg_resolution_time": null,
            "resolutions_count": null,
            "reply_time": null,
            "queue_time": null,
            "operation_time": null
        }
      });
      commit(types.default.TOGGLE_ACCOUNT_REPORT_LOADING, false);
    } else {
      // Check cache first
      const cacheKey = `reports_summary_${reportObj.from}_${reportObj.to}_${reportObj.type}_${reportObj.id || 'null'}`;
      const cachedData = getFromCache(cacheKey);
      
      if (cachedData) {
        commit(types.default.SET_ACCOUNT_SUMMARY, cachedData);
        return;
      }
      
      Report.getSummary(
        reportObj.from,
        reportObj.to,
        reportObj.type,
        reportObj.id,
        reportObj.groupBy,
        reportObj.businessHours
      )
        .then(accountSummary => {
          // Save to cache
          saveToCache(cacheKey, accountSummary.data);
          
          commit(types.default.SET_ACCOUNT_SUMMARY, accountSummary.data);
        })
        .catch(() => {
          commit(types.default.TOGGLE_ACCOUNT_REPORT_LOADING, false);
        });
    }
  },
  fetchBotSummary({ commit }, reportObj) {
    // Check cache first
    const cacheKey = `reports_bot_summary_${reportObj.from}_${reportObj.to}_${reportObj.classification_id || 'null'}`;
    const cachedData = getFromCache(cacheKey);
    
    if (cachedData) {
      commit(types.default.SET_BOT_SUMMARY, cachedData);
      return;
    }
    
    Report.getBotSummary({
      from: reportObj.from,
      to: reportObj.to,
      groupBy: reportObj.groupBy,
      businessHours: reportObj.businessHours,
      classification_id: reportObj.classification_id
    })
      .then(botSummary => {
        // Save to cache
        saveToCache(cacheKey, botSummary.data);
        
        commit(types.default.SET_BOT_SUMMARY, botSummary.data);
      })
      .catch(() => {
        commit(types.default.TOGGLE_ACCOUNT_REPORT_LOADING, false);
      });
  },
  fetchAccountConversationMetric({ commit }, reportObj) {
    commit(types.default.TOGGLE_ACCOUNT_CONVERSATION_METRIC_LOADING, true);
    
    // Check cache first
    const cacheKey = `reports_account_conversation_metric_${reportObj.type}`;
    const cachedData = getFromCache(cacheKey);
    
    if (cachedData) {
      commit(types.default.SET_ACCOUNT_CONVERSATION_METRIC, cachedData);
      commit(types.default.TOGGLE_ACCOUNT_CONVERSATION_METRIC_LOADING, false);
      return;
    }
    
    Report.getConversationMetric(reportObj.type)
      .then(accountConversationMetric => {
        // Save to cache
        saveToCache(cacheKey, accountConversationMetric.data);
        
        commit(
          types.default.SET_ACCOUNT_CONVERSATION_METRIC,
          accountConversationMetric.data
        );
        commit(types.default.TOGGLE_ACCOUNT_CONVERSATION_METRIC_LOADING, false);
      })
      .catch(() => {
        commit(types.default.TOGGLE_ACCOUNT_CONVERSATION_METRIC_LOADING, false);
      });
  },
  fetchAgentConversationMetric({ commit }, reportObj) {
    commit(types.default.TOGGLE_AGENT_CONVERSATION_METRIC_LOADING, true);
    
    // Check cache first
    const cacheKey = `reports_agent_conversation_metric_${reportObj.type}_${reportObj.page}`;
    const cachedData = getFromCache(cacheKey);
    
    if (cachedData) {
      commit(types.default.SET_AGENT_CONVERSATION_METRIC, cachedData);
      commit(types.default.TOGGLE_AGENT_CONVERSATION_METRIC_LOADING, false);
      return;
    }
    
    Report.getConversationMetric(reportObj.type, reportObj.page)
      .then(agentConversationMetric => {
        // Save to cache
        saveToCache(cacheKey, agentConversationMetric.data);
        
        commit(
          types.default.SET_AGENT_CONVERSATION_METRIC,
          agentConversationMetric.data
        );
        commit(types.default.TOGGLE_AGENT_CONVERSATION_METRIC_LOADING, false);
      })
      .catch(() => {
        commit(types.default.TOGGLE_AGENT_CONVERSATION_METRIC_LOADING, false);
      });
  },
  clearMetricsCache() {
    // Clear all reports cache from localStorage
    try {
      // Get the current account ID
      const accountId = getCurrentAccountIdFromURL();
      const accountPrefix = accountId ? `${accountId}_reports_` : 'reports_';
      
      // Get all keys in localStorage that start with the account-specific prefix
      const keysToRemove = [];
      for (let i = 0; i < localStorage.length; i++) {
        const key = localStorage.key(i);
        if (key && key.startsWith(accountPrefix)) {
          keysToRemove.push(key);
        }
      }
      
      // Remove all identified cache keys
      keysToRemove.forEach(key => {
        localStorage.removeItem(key);
      });
      
      console.log(`Cleared ${keysToRemove.length} cache entries for reports in account ${accountId}`);
      return true;
    } catch (error) {
      console.error('Error clearing metrics cache:', error);
      return false;
    }
  },
  downloadAgentReports(_, reportObj) {
    return Report.getAgentReports(reportObj)
      .then(response => {
        downloadCsvFile(reportObj.fileName, response.data);
        AnalyticsHelper.track(REPORTS_EVENTS.DOWNLOAD_REPORT, {
          reportType: 'agent',
          businessHours: reportObj?.businessHours,
        });
      })
      .catch(error => {
        console.error(error);
      });
  },
  downloadLabelReports(_, reportObj) {
    return Report.getLabelReports(reportObj)
      .then(response => {
        downloadCsvFile(reportObj.fileName, response.data);
        AnalyticsHelper.track(REPORTS_EVENTS.DOWNLOAD_REPORT, {
          reportType: 'label',
          businessHours: reportObj?.businessHours,
        });
      })
      .catch(error => {
        console.error(error);
      });
  },
  downloadInboxReports(_, reportObj) {
    return Report.getInboxReports(reportObj)
      .then(response => {
        downloadCsvFile(reportObj.fileName, response.data);
        AnalyticsHelper.track(REPORTS_EVENTS.DOWNLOAD_REPORT, {
          reportType: 'inbox',
          businessHours: reportObj?.businessHours,
        });
      })
      .catch(error => {
        console.error(error);
      });
  },
  downloadTeamReports(_, reportObj) {
    return Report.getTeamReports(reportObj)
      .then(response => {
        downloadCsvFile(reportObj.fileName, response.data);
        AnalyticsHelper.track(REPORTS_EVENTS.DOWNLOAD_REPORT, {
          reportType: 'team',
          businessHours: reportObj?.businessHours,
        });
      })
      .catch(error => {
        console.error(error);
      });
  },
  downloadAccountConversationHeatmap(_, reportObj) {
    Report.getConversationTrafficCSV()
      .then(response => {
        downloadCsvFile(
          generateFileName({
            type: 'Conversation traffic',
            to: reportObj.to,
          }),
          response.data
        );

        AnalyticsHelper.track(REPORTS_EVENTS.DOWNLOAD_REPORT, {
          reportType: 'conversation_heatmap',
          businessHours: false,
        });
      })
      .catch(error => {
        console.error(error);
      });
  },
  async fetchClassificationConversationMetric({ commit }, payload) {
    commit(types.default.SET_CLASSIFICATION_CONVERSATION_METRICS_UI_FLAG, true);
    try {
      const response = await Report.getConversations(payload);
      commit(types.default.SET_CLASSIFICATION_CONVERSATION_METRICS, response.data);
    } catch (error) {
      // Handle error
      console.error(error);
    } finally {
      commit(types.default.SET_CLASSIFICATION_CONVERSATION_METRICS_UI_FLAG, false);
    }
  },
  async downloadAgentReports(_, { from, to, fileName, type = 'agent', timezone_offset }) {
    try {
      const response = await ReportsAPI.downloadAgentReports({
        from,
        to,
        type,
        timezone_offset,
      });
      downloadCsvFile(response.data, fileName);
    } catch (error) {
      console.error(error);
    }
  },
  async downloadLabelReports(_, { from, to, fileName, timezone_offset }) {
    try {
      const response = await ReportsAPI.downloadLabelReports({
        from,
        to,
        timezone_offset,
      });
      downloadCsvFile(response.data, fileName);
    } catch (error) {
      console.error(error);
    }
  },
  async downloadClassificationReports(_, { from, to, fileName, timezone_offset }) {
    try {
      const response = await Report.downloadClassificationReports({
        from,
        to,
        timezone_offset
      });
      downloadCsvFile(fileName, response.data);
      AnalyticsHelper.track(REPORTS_EVENTS.DOWNLOAD_REPORT, {
        reportType: 'classification',
        businessHours: false,
      });
    } catch (error) {
      console.error(error);
    }
  },
  async downloadClassificationConversationsReports(_, { from, to, fileName, timezone_offset, classification_id }) {
    try {
      const response = await Report.downloadClassificationConversationsReports({
        from,
        to,
        timezone_offset,
        classification_id,
      });
      downloadCsvFile(fileName, response.data);
      AnalyticsHelper.track(REPORTS_EVENTS.DOWNLOAD_REPORT, {
        reportType: 'classification_conversations',
        businessHours: false,
      });
    } catch (error) {
      console.error(error);
    }
  },
};

const mutations = {
  [types.default.SET_ACCOUNT_REPORTS](_state, { metric, data }) {
    _state.accountReport.data[metric] = data;
  },
  [types.default.SET_HEATMAP_DATA](_state, heatmapData) {
    _state.overview.accountConversationHeatmap = heatmapData;
  },
  [types.default.TOGGLE_ACCOUNT_REPORT_LOADING](_state, { metric, value }) {
    _state.accountReport.isFetching[metric] = value;
  },
  [types.default.TOGGLE_HEATMAP_LOADING](_state, flag) {
    _state.overview.uiFlags.isFetchingAccountConversationsHeatmap = flag;
  },
  [types.default.SET_ACCOUNT_SUMMARY](_state, summaryData) {
    _state.accountSummary = summaryData;
  },
  [types.default.SET_BOT_SUMMARY](_state, summaryData) {
    _state.botSummary = summaryData;
  },
  [types.default.SET_ACCOUNT_CONVERSATION_METRIC](_state, metricData) {
    _state.overview.accountConversationMetric = metricData;
  },
  [types.default.TOGGLE_ACCOUNT_CONVERSATION_METRIC_LOADING](_state, flag) {
    _state.overview.uiFlags.isFetchingAccountConversationMetric = flag;
  },
  [types.default.SET_AGENT_CONVERSATION_METRIC](_state, metricData) {
    _state.overview.agentConversationMetric = metricData;
  },
  [types.default.TOGGLE_AGENT_CONVERSATION_METRIC_LOADING](_state, flag) {
    _state.overview.uiFlags.isFetchingAgentConversationMetric = flag;
  },
  [types.default.SET_CLASSIFICATION_HANDOFF_CONVERSATION_METRIC](_state, metricData) {
    _state.overview.classificationHandoffConversationMetric = metricData;
  },
  [types.default.TOGGLE_CLASSIFICATION_HANDOFF_CONVERSATION_METRIC_LOADING](_state, flag) {
    _state.overview.uiFlags.isFetchingClassificationHandoffConversationMetric = flag;
  },
  [types.default.SET_CLASSIFICATION_BAD_RATING_CONVERSATION_METRIC](_state, metricData) {
    _state.overview.classificationBadRatingConversationMetric = metricData;
  },
  [types.default.TOGGLE_CLASSIFICATION_BAD_RATING_CONVERSATION_METRIC_LOADING](_state, flag) {
    _state.overview.uiFlags.isFetchingClassificationBadRatingConversationMetric = flag;
  },
  [types.default.SET_CLASSIFICATION_CONVERSATION_METRICS](_state, data) {
    _state.overview.classificationConversationMetric = data;
  },
  [types.default.SET_CLASSIFICATION_CONVERSATION_METRICS_UI_FLAG](_state, flag) {
    _state.overview.uiFlags.isFetchingClassificationConversationMetric = flag;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
