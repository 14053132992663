/* global axios */
import ApiClient from './ApiClient';

const getTimeOffset = () => -new Date().getTimezoneOffset() / 60;

class ReportsAPI extends ApiClient {
  constructor() {
    super('reports', { accountScoped: true, apiVersion: 'v2' });
  }

  getReports({
    metric,
    from,
    to,
    type = 'account',
    id,
    groupBy,
    businessHours,
    classification_id
  }) {
    return axios.get(`${this.url}`, {
      params: {
        metric,
        since: from,
        until: to,
        type,
        id,
        group_by: groupBy,
        business_hours: businessHours,
        classification_id,
        timezone_offset: getTimeOffset(),
      },
    });
  }

  // eslint-disable-next-line default-param-last
  getSummary(since, until, type = 'account', id, groupBy, businessHours) {
    return axios.get(`${this.url}/summary`, {
      params: {
        since,
        until,
        type,
        id,
        group_by: groupBy,
        business_hours: businessHours,
        timezone_offset: getTimeOffset(),
      },
    });
  }

  getConversationMetric(type = 'account', page = 1, from, to) {
    return axios.get(`${this.url}/conversations`, {
      params: {
        type,
        page,
        since: from,
        until: to
      },
    });
  }

  getAgentReports({ from: since, to: until, businessHours, id }) {
    return axios.get(`${this.url}/agents`, {
      params: { since, until, business_hours: businessHours, id },
    });
  }

  getConversationTrafficCSV() {
    return axios.get(`${this.url}/conversation_traffic`, {
      params: { timezone_offset: getTimeOffset() },
    });
  }

  getLabelReports({ from: since, to: until, businessHours, id }) {
    return axios.get(`${this.url}/labels`, {
      params: { since, until, business_hours: businessHours, id },
    });
  }

  getClassificationReports({ from: since, to: until, businessHours, id, format }) {
    return axios.get(`${this.url}/classifications`, {
      params: {
        since,
        until,
        business_hours: businessHours,
        id,
        format,
        timezone_offset: getTimeOffset(),
        include_conversations: true,
        include_metrics: true,
        include_custom_attributes: true,
        include_csat: true
      },
    });
  }

  getInboxReports({ from: since, to: until, businessHours, id }) {
    return axios.get(`${this.url}/inboxes`, {
      params: { since, until, business_hours: businessHours, id },
    });
  }

  getTeamReports({ from: since, to: until, businessHours, id }) {
    return axios.get(`${this.url}/teams`, {
      params: { since, until, business_hours: businessHours, id },
    });
  }

  getBotMetrics({ from, to, businessHours, classification_id } = {}) {
    return axios.get(`${this.url}/bot_metrics`, {
      params: { since: from, until: to, business_hours: businessHours, classification_id },
    });
  }

  getBotSummary({ from, to, groupBy, businessHours, classification_id } = {}) {
    return axios.get(`${this.url}/bot_summary`, {
      params: {
        since: from,
        until: to,
        type: 'account',
        group_by: groupBy,
        business_hours: businessHours,
        classification_id: classification_id
      },
    });
  }

  getConversations(params) {
    return axios.get(`${this.url}/conversations`, {
      params: {
        ...params,
        timezone_offset: getTimeOffset(),
      },
    });
  }

  downloadClassificationReports({ from, to, timezone_offset }) {
    return axios.get(`${this.url}/classifications`, {
      params: {
        since: from,
        until: to,
        timezone_offset,
        format: 'csv',
        include_conversations: true,
        include_metrics: true,
        include_custom_attributes: true,
        include_csat: true
      },
    });
  }

  downloadClassificationConversationsReports({ from, to, timezone_offset, classification_id }) {
    return axios.get(`${this.url}/classification_conversations`, {
      params: {
        since: from,
        until: to,
        timezone_offset,
        classification_id,
        format: 'csv'
      },
    });
  }
}

export default new ReportsAPI();
