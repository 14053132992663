import * as MutationHelpers from 'shared/helpers/vuex/mutationHelpers';
import types from '../mutation-types';
import ClassificationsAPI from '../../api/classifications';
import AnalyticsHelper from '../../helper/AnalyticsHelper';
import { CLASSIFICATION_EVENTS } from '../../helper/AnalyticsHelper/events';

export const state = {
  records: [],
  uiFlags: {
    isFetching: false,
    isFetchingItem: false,
    isCreating: false,
    isDeleting: false,
  },
};

export const getters = {
  getClassifications(_state) {
    return _state.records;
  },
  getUIFlags(_state) {
    return _state.uiFlags;
  },
  getActiveClassifications(_state) {
    return _state.records
      .filter(record => record.active)
      .sort((a, b) => a.friendly_name.localeCompare(b.friendly_name));
  },
  getChildrenClassifications(_state) {
    return _state.records
      .filter(record => record.parent_classification_id)
      .sort((a, b) => a.name.localeCompare(b.name));
  },
  getParentClassifications(_state) {
    return _state.records
      .filter(record => !record.parent_classification_id)
      .sort((a, b) => a.name.localeCompare(b.name));
  }
};

export const actions = {
  get: async function getClassifications({ commit }) {
    try {
      commit(types.SET_CLASSIFICATION_UI_FLAG, { isFetching: true });
      const response = await ClassificationsAPI.get(true);
      const sortedClassifications = response.data.payload.sort((a, b) =>
        a.friendly_name.localeCompare(b.friendly_name)
      );
      commit(types.SET_CLASSIFICATIONS, sortedClassifications);
    } catch (error) {
      // Ignore error
    } finally {
      commit(types.SET_CLASSIFICATION_UI_FLAG, { isFetching: false });
    }
  },

  create: async function createClassifications({ commit }, cannedObj) {
    commit(types.SET_CLASSIFICATION_UI_FLAG, { isCreating: true });
    try {
      const response = await ClassificationsAPI.create(cannedObj);
      AnalyticsHelper.track(CLASSIFICATION_EVENTS.CREATE);
      commit(types.ADD_CLASSIFICATION, response.data);
    } catch (error) {
      const errorMessage = error?.response?.data?.message;
      throw new Error(errorMessage);
    } finally {
      commit(types.SET_CLASSIFICATION_UI_FLAG, { isCreating: false });
    }
  },

  update: async function updateClassifications({ commit }, { id, ...updateObj }) {
    commit(types.SET_CLASSIFICATION_UI_FLAG, { isUpdating: true });
    try {
      const response = await ClassificationsAPI.update(id, updateObj);
      AnalyticsHelper.track(CLASSIFICATION_EVENTS.UPDATE);
      commit(types.EDIT_CLASSIFICATION, response.data);
    } catch (error) {
      throw new Error(error);
    } finally {
      commit(types.SET_CLASSIFICATION_UI_FLAG, { isUpdating: false });
    }
  },

  delete: async function deleteClassifications({ commit }, id) {
    commit(types.SET_CLASSIFICATION_UI_FLAG, { isDeleting: true });
    try {
      await ClassificationsAPI.delete(id);
      AnalyticsHelper.track(CLASSIFICATION_EVENTS.DELETED);
      commit(types.DELETE_CLASSIFICATION, id);
    } catch (error) {
      throw new Error(error);
    } finally {
      commit(types.SET_CLASSIFICATION_UI_FLAG, { isDeleting: false });
    }
  },
};

export const mutations = {
  [types.SET_CLASSIFICATION_UI_FLAG](_state, data) {
    _state.uiFlags = {
      ..._state.uiFlags,
      ...data,
    };
  },

  [types.SET_CLASSIFICATIONS]: MutationHelpers.set,
  [types.ADD_CLASSIFICATION]: MutationHelpers.create,
  [types.EDIT_CLASSIFICATION]: MutationHelpers.update,
  [types.DELETE_CLASSIFICATION]: MutationHelpers.destroy,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};