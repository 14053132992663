import ApiClient from './ApiClient';

class CampaignsAPI extends ApiClient {
  constructor() {
    super('campaigns', { accountScoped: true });
  }

  createWithCSV(formData) {
    // No need to wrap FormData - it's already formatted properly
    return window.axios.post(this.url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }
  
  trigger(id) {
    return window.axios.post(`${this.url}/${id}/trigger`);
  }
  
  previewAudience(data) {
    const formData = new FormData();
    
    // Add CSV file if present
    if (data.csvFile) {
      formData.append('csv_file', data.csvFile);
    }
    
    // Add audience if present
    if (data.audience && data.audience.length > 0) {
      formData.append('audience', JSON.stringify(data.audience));
    }
    
    return window.axios.post(`${this.url}/preview_audience`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }
}

export default new CampaignsAPI();
